<template>
    <div class="login-container">
        <b-overlay :show="loading"
                   :variant="variant"
                   :opacity="opacity"
                   :blur="blur"
                   spinner-variant="primary"
                   spinner-type="grow"
                   spinner-large
                   class="loaderPreview"
                   rounded="sm">

            <div class="row">
                <div class="col-md-12">
                    <div class="text-center">
                        <div style="float:none; display:inline-block;">
                            <img src="../../../assets/images/logo/logo.svg" style="width:350px;" />
                        </div>
                    </div>
                </div>
            </div>

            <!--terms & conditions-->
            <b-card title="Confirmation of Access: Landingly" v-if="IsAgreeTerms">
                <div class="divTerms">
                    <p>By logging into Landingly software, you acknowledge and confirm that you have received immediate access.<br /></p>
                    <p>
                        This access is a result of your valid transaction and signifies the successful fulfillment of your order.Your login and acceptance of this notification will be saved
                        and may be used as evidence in the event of any payment dispute, and it will be provided to the bank upon request.<br /><br />
                        If you have any questions or concerns regarding your order or access, please contact our customer support team immediately at support@ecoverly.com.
                    </p>

                    <div class="text-center my-2">
                        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                                  type="button"
                                  class="btn mr-1"
                                  @click="submitTerms"
                                  variant="primary">
                            Ok
                        </b-button>
                    </div>
                </div>
            </b-card>
            
            <form id="SetupUserAccountForm" v-if="IsSubdomainSetup">
                    <b-row class="match-height">
                        <b-col lg="12">
                            <div class="text-center m-b-md">
                                <h4> Welcome to Landingly</h4>
                                <h4>Please Complete Your Profile</h4>
                                <br />

                                <h3 class="stepTitle" style="font-weight:bold;">
                                    Step {{ currentStep }} - {{ meta.pageTitle }}
                                </h3>

                                <button type="button"
                                        color="#7367F0"
                                        style="font-size:18px;"
                                        class="btn btn-link btn-sm"
                                        @click="showVideoSubDomain">
                                    <feather-icon icon="PlayCircleIcon"
                                                  size="18"
                                                  class="mr-50" />
                                    <!--<FeatherIcon style="color:#7367f0;font-size:24px;" />-->
                                    [TUTORIAL] How to setup your Subdomain?
                                </button>

                                <div class="row justify-content-center mt-2">
                                    <div class="col-md-12">
                                        <b-card>
                                            <!-- accoint details tab -->
                                            <div class="divConfigureDomain" v-if="currentStep === 1">
                                                <!-- accoint details tab -->

                                                <validation-observer ref="subDomainRules"
                                                                     tag="form">
                                                    <b-row>
                                                        <b-col md="12">
                                                            <b-form-group label-for="Subdomain" class="text-left">
                                                                <p>
                                                                    Subdomain <span style="color: #ea5455;">(You cannot change this later)</span>
                                                                </p>
                                                                <validation-provider #default="{ errors }"
                                                                                     name="sDomani"
                                                                                     rules="required|no_special_characters|alpha_numeric_with_alpha_first">
                                                                    <b-input-group append=".landingly.com" prepend="https://">
                                                                        <b-form-input id="Subdomain"
                                                                                      v-model="Subdomain"
                                                                                      @input="onInput"
                                                                                      :state="errors.length > 0 ? false:null"
                                                                                      placeholder="Please Enter Your Subdomain" />
                                                                    </b-input-group>
                                                                    <span class="help-block small">Your unique subdomain to setup your account.</span>
                                                                    <div><small class="text-danger">{{ errors[0] }}</small></div>

                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </validation-observer>
                                                <input type="button"
                                                       value="Check Availability?"
                                                       name="action"
                                                       class="btn btn-primary btn-block"
                                                       @click.prevent="verifySubDomain" />
                                                <a href="#"
                                                   class="btn btn-success btn-block" :class="{ disabled: isNextButtonDisabled }" @click="nextStep">
                                                    Next
                                                </a>

                                            </div>

                                            <!-- address  -->
                                            <div class="divConfigureAddress text-left" v-if="currentStep === 2">
                                                <validation-observer ref="addressRules"
                                                                     tag="form">
                                                    <b-row>
                                                        <b-col md="12">
                                                            <b-form-group label="Address"
                                                                          label-for="AddressLine1">
                                                                <validation-provider #default="{ errors }"
                                                                                     name="Address"
                                                                                     rules="required">
                                                                    <b-form-input id="AddressLine1"
                                                                                  v-model="AddressLine1"
                                                                                  :state="shouldValidateAddress && errors.length > 0 ? false:null"
                                                                                  placeholder="Plaese Enter Your Address" />
                                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <b-form-group label="Country"
                                                                          label-for="CountryId">
                                                                <validation-provider #default="{ errors }"
                                                                                     name="CountryId"
                                                                                     rules="required">
                                                                    <select class="form-control"
                                                                            name="CountryId"
                                                                            id="CountryId"
                                                                            v-model="CountryId"
                                                                            :class="{ 'is-invalid': shouldValidateAddress && errors.length > 0 }"
                                                                            :state="shouldValidateAddress && errors.length > 0 ? false : null">
                                                                        <option value=""
                                                                                selected>
                                                                            Please Select Country
                                                                        </option>
                                                                        <option v-for="country in CountryList"
                                                                                :value="country.Id">
                                                                            {{ country.Name}}
                                                                        </option>
                                                                    </select>

                                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>

                                                        <b-col md="12">
                                                            <b-form-group label="City"
                                                                          label-for="City">
                                                                <validation-provider #default="{ errors }"
                                                                                     name="Pincode"
                                                                                     rules="required">
                                                                    <b-form-input id="City"
                                                                                  v-model="City"
                                                                                  :state="shouldValidateAddress && errors.length > 0 ? false:null"
                                                                                  placeholder="Please Enter City" />
                                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                        <b-col md="12">
                                                            <b-form-group label="State"
                                                                          label-for="Province">
                                                                <validation-provider #default="{ errors }"
                                                                                     name="province"
                                                                                     rules="required">
                                                                    <b-form-input id="Province"
                                                                                  v-model="Province"
                                                                                  :state="shouldValidateAddress && errors.length > 0 ? false:null"
                                                                                  placeholder="Please Enter Province" />
                                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>

                                                        <b-col md="12">
                                                            <b-form-group label="Zip Code"
                                                                          label-for="ZipCode">
                                                                <validation-provider #default="{ errors }"
                                                                                     name="ZipCode"
                                                                                     rules="required">
                                                                    <b-form-input id="ZipCode"
                                                                                  v-model="ZipCode"
                                                                                  v-alphanumeric
                                                                                  :state="shouldValidateAddress && errors.length > 0 ? false:null"
                                                                                  placeholder="Please Enter Zip Code" />
                                                                    <div class="popup-validation"><small class="text-danger">{{ errors[0] }}</small></div>
                                                                </validation-provider>
                                                            </b-form-group>
                                                        </b-col>
                                                    </b-row>
                                                </validation-observer>
                                                <input type="submit"
                                                       value="Submit"
                                                       name="action"
                                                       class="btn btn-primary btn-block"
                                                       @click.prevent="formSubmitted" />
                                                <a href="#"
                                                   class="btn btn-success btn-block" @click="previousStep">
                                                    Back
                                                </a>

                                            </div>
                                        </b-card>
                                    </div>
                                </div>


                            </div>
                        </b-col>
                    </b-row>
                </form>

        </b-overlay>

        <b-modal v-model="videoModalVisible" title="[TUTORIAL] How to setup your Subdomain?" hide-footer>
            <div class="row">
                <div class="col-lg-12 text-center">
                    <iframe style="width:100% !important;height:400px !important;"
                            :src="videoUrl"
                            frameborder="0"
                            webkitallowfullscreen=""
                            mozallowfullscreen=""
                            allowfullscreen=""></iframe>
                </div>
            </div>
        </b-modal>

    </div>


</template>

<style>
    .login-container {
        max-width: 600px;
        margin: auto;
        padding-top: 4%;
    }
</style>

<script>
    import Ripple from "vue-ripple-directive";
    import {
        required,
        email,
        confirmed,
        url,
        between,
        alpha,
        integer,
        password,
        min,
        max,
        digits,
        alphaDash,
        length,
        no_special_characters,
        alpha_numeric_with_alpha_first,
        alphaNum
    } from "@validations";
    import BCardCode from "@core/components/b-card-code/BCardCode.vue";
    import {
        BOverlay,
        BTable,
        BAvatar,
        BBadge,
        BFormGroup,
        BFormSelect,
        BPagination,
        BInputGroup,
        BFormInput,
        BInputGroupAppend,
        BButton,
        BFormCheckbox,
        BCard,
        BCardBody,
        BForm,
        BRow,
        BCol,
        BLink,
        BBreadcrumb, BBreadcrumbItem,
        VBTooltip,
        BFormInvalidFeedback,
        BInputGroupPrepend
    } from "bootstrap-vue";

    import { FormWizard, TabContent } from 'vue-form-wizard'
    import vSelect from 'vue-select'
    import { ValidationProvider, ValidationObserver } from 'vee-validate'
    import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
    import 'vue-form-wizard/dist/vue-form-wizard.min.css'

    import { codeKitchenSink } from "../../table/bs-table/code.js";
    import jwt from "@/auth/jwt/useJwt";
    import { $themeConfig } from "@themeConfig";
    import Multiselect from "vue-multiselect";
    import moment from "moment";
    import { extend } from 'vee-validate';
    import { getHomeRouteForLoggedInUser } from '@/auth/utils'

    // Define the custom rule
    extend("no_special_characters", (value) => {
        const regexSpecialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>?]/;
        return !regexSpecialChars.test(value) || "Special characters are not allowed.";
    });

    //extend("no_white_spaces", (value) => {
    //    const regexWhiteSpaces = /\s/;
    //    return !regexWhiteSpaces.test(value) || "White spaces are not allowed.";
    //});

    extend("alpha_numeric_with_alpha_first", (value) => {
        const regexAlphaNumeric = /^[A-Za-z0-9]+$/;
        const regexStartsWithAlpha = /^[A-Za-z]/;
        const regexWhiteSpaces = /\s/;

        if (regexWhiteSpaces.test(value)) {
            return "White spaces are not allowed.";
        }

        if (value.length > 60) {
            return "Maximum 60 characters are allow.";
        }

        if (value.length < 3) {
            return "Manimum 3 characters are required.";
        }

        return regexAlphaNumeric.test(value) && regexStartsWithAlpha.test(value[0]) || "First letter should be alphabet.";
    });


    extend('required', {
        message: 'This Field is required',
    });
    extend('email', {
        message: 'Invalid email address',
    });
    export default {
        metaInfo: {
            title: 'Landingly | Setup User Account'
        },
        components: {
            BCard,
            Ripple,
            Multiselect,
            BOverlay,
            BCardCode,
            BForm,
            BRow,
            BCol,
            BTable,
            ValidationProvider,
            ValidationObserver,
            BAvatar,
            BBadge,
            BFormGroup,
            BFormSelect,
            BPagination,
            BInputGroup,
            BFormInput,
            BInputGroupAppend,
            BInputGroupPrepend,
            BButton,
            BFormCheckbox,
            BCardBody,
            BLink,
            BBreadcrumb,
            BBreadcrumbItem,
            FormWizard,
            codeKitchenSink,
            moment,
            TabContent,
            vSelect,
            BFormInvalidFeedback,
        },
        data() {
            return {
                IsAgreeTerms: false,
                IsSubdomainSetup: false,
                isModalVisible: false,
                shouldValidateAddress: false,
                variant: "light",
                opacity: 0.85,
                blur: "2px",
                isNextButtonDisabled: true,
                meta: {
                    pageTitle: 'Configure Your Subdomain',
                    breadcrumb: [
                        {
                            text: 'Setup User Account',
                            active: true
                        },
                    ],
                },
                currentStep: 1,
                loading: true,
                modalShow: false,
                videoModalVisible: false,
                videoUrl: "https://player.vimeo.com/video/381022205",
                dataLoaded: false,
                CountryList: null,
                Subdomain: null,
                UserId: null,
                AddressLine1: null,
                CountryId: null,
                City: null,
                Province: null,
                ZipCode: null,

                WebAPIURL: $themeConfig.app.apiURL,
                required,
                alphaNum,
                error: null,
                no_special_characters,
                alpha_numeric_with_alpha_first,
                isValidUsername: true,
            };
        },
        directives: {
            'b-tooltip': VBTooltip,
            Ripple,
            alphanumeric: {
                bind(el) {
                    el.addEventListener('input', function () {
                        // Space Hyphen and Alphanumeric allowed
                        let sanitizedValue = this.value.replace(/[^a-zA-Z0-9\s-]/g, '');

                        // Limit the length to the specified maximum
                        if (sanitizedValue.length > 20) {
                            sanitizedValue = sanitizedValue.substr(0, 20);
                        }

                        this.value = sanitizedValue;
                    });
                }
            },
        },
        created() {
            var subDomain = jwt.getSubDomain();
            // console.log(subDomain);
            if (subDomain != null) {
                this.$router.replace('/')
            }
            else {
                this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', true);
                this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: 'hidden' });
                this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: 'hidden' });

                this.getTerms();
                this.fetchCountriesList();
            }

        },
        destroyed() {
            this.$store.commit('appConfig/UPDATE_NAV_MENU_HIDDEN', this.menuHidden);
            this.$store.commit('appConfig/UPDATE_NAVBAR_CONFIG', { type: this.navbarType });
            this.$store.commit('appConfig/UPDATE_FOOTER_CONFIG', { type: this.footerType });
        },

        methods: {
            filterInput() {
                // Use a regular expression to remove non-alphanumeric characters
                this.inputValue = this.inputValue.replace(/[^a-zA-Z0-9]/g, '');
            },
            formSubmitted() {
                this.validationAddressForm()
                    .then(() => {
                        // Validation succeeded, perform form submission
                        this.saveUserData();
                    })
                    .catch(() => {
                        // console.log("Validation failed. Please check the form.");
                    });
            },

            showVideoSubDomain() {
                this.videoModalVisible = true;
            },

            onInput() {
                // Regular expression to check for special characters and white spaces
                const regexSpecialChars = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?\s]/;
                this.hasSpecialCharactersOrWhiteSpace = regexSpecialChars.test(this.Subdomain);

                // Regular expression to check if the username starts with an alphabet
                const regexStartsWithAlpha = /^[A-Za-z]/;
                this.startsWithAlpha = regexStartsWithAlpha.test(this.Subdomain);

                // Regular expression to check if only alphanumeric characters are allowed after the first letter
                const regexValidUsername = /^[A-Za-z][A-Za-z0-9]*$/;
                this.isValidUsername = regexValidUsername.test(this.Subdomain);
            },

            validationSubDomainForm() {
                return new Promise((resolve, reject) => {
                    this.$refs.subDomainRules.validate().then(success => {
                        if (success) {
                            resolve(true)

                        } else {
                            reject()
                        }
                    })
                })
            },

            getTerms: async function () {
                const access_token = await jwt.getToken();
                var axios = require('axios');
                var qs = require('qs');
                var UserId = jwt.getUserId();
                var data = qs.stringify({
                    'UserId': UserId,
                });

                var self = this;

                var config = {
                    method: 'get',
                    url: '' + this.WebAPIURL + '/api/UserProfile/getTerms?' + data,
                    headers: {
                        'Authorization': 'bearer ' + access_token
                    },
                    data: data
                }

                axios(config)
                    .then(function (response) {
                        if (response.data.IsAgreeTerms) {
                            self.IsSubdomainSetup = true;
                            self.IsAgreeTerms = false;
                            
                        }
                        else {
                            self.IsAgreeTerms = true;
                            self.IsSubdomainSetup = false;
                        }
                        self.loading = false;
                    })
                    .catch(function (error) {
                        console.log(error);
                        self.loading = false;
                    });
            },

            submitTerms: async function () {
                const access_token = await jwt.getToken();
                var axios = require('axios');
                var qs = require('qs');
                var UserId = jwt.getUserId();
                var data = qs.stringify({
                    'UserId': UserId,
                    'IsAgreedTerms': true,
                });

                var self = this;

                var config = {
                    method: 'post',
                    url: '' + this.WebAPIURL + '/api/UserProfile/AgreeTerms?' + data,
                    headers: {
                        'Authorization': 'bearer ' + access_token
                    },
                    data: data
                }

                axios(config)
                    .then(function (response) {
                        self.IsSubdomainSetup = true;
                        self.IsAgreeTerms = false;
                    })
                    .catch(function (error) {
                        self.IsAgreeTerms = false;
                    });
            },

            nextStep() {
                if (this.currentStep < 2) {
                    this.currentStep++;
                    this.meta.pageTitle = "Setup Account Information";
                }
            },
            previousStep() {
                if (this.currentStep > 1) {
                    this.currentStep--;
                    this.meta.pageTitle = "Configure Your Subdomain";

                    this.isNextButtonDisabled = true;
                    this.shouldValidateAddress = false;
                }
            },

            validationAddressForm() {
                this.shouldValidateAddress = true;

                return new Promise((resolve, reject) => {
                    this.$refs.addressRules.validate().then(success => {
                        if (success) {
                            resolve(true)
                        } else {
                            reject()
                        }
                    })
                })
            },

            verifySubDomain: async function () {
                const access_token = await jwt.getToken();
                this.error = false;
                var self = this;

                var axios = require('axios');
                var qs = require('qs');

                var data = qs.stringify({
                    'Subdomain': this.Subdomain,
                });

                return new Promise((resolve, reject) => {
                    this.$refs.subDomainRules.validate().then(success => {
                        if (success) {

                            this.loading = true;
                            var config = {
                                method: 'post',
                                url: '' + this.WebAPIURL + '/api/UserProfile/VerifyUserDomain?' + data,
                                headers: {
                                    'Authorization': 'bearer ' + access_token
                                },
                                data: data
                            }
                            axios(config)
                                .then(function (response) {
                                    self.loading = false;
                                    if (response.data == "Taken") {
                                        self.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: `Subdomain has been taken, please choose a different subdomain`,
                                                solid: false,
                                                variant: 'danger',
                                                icon: 'faUser',
                                            },
                                        })
                                        self.isNextButtonDisabled = true;
                                        reject()
                                    }
                                    else {
                                        self.$toast({
                                            component: ToastificationContent,
                                            position: 'top-right',
                                            props: {
                                                title: `Subdomain Available`,
                                                solid: false,
                                                variant: 'success',
                                                icon: 'faUser',
                                            },
                                        });

                                        self.isNextButtonDisabled = false;
                                        resolve(true)

                                    }
                                })
                                .catch(function (error) {
                                    // console.log(error);
                                    self.loading = false;
                                    reject()
                                });


                        } else {
                            reject()
                        }
                    })
                })

            },

            saveUserData: async function () {
                const access_token = await jwt.getToken();
                this.error = null
                var self = this;
                this.loading = true
                var axios = require('axios');
                var qs = require('qs');
                var UserId = jwt.getUserId();
                var data = qs.stringify({
                    'Subdomain': this.Subdomain,
                    'UserId': UserId,
                    'AddressLine1': this.AddressLine1,
                    'CountryId': this.CountryId,
                    'City': this.City,
                    'Province': this.Province,
                    'ZipCode': this.ZipCode,
                    'action': '',
                });

                var config = {
                    method: 'post',
                    url: '' + this.WebAPIURL + '/api/UserProfile/SetupUserAccount?' + data,
                    headers: {
                        'Authorization': 'bearer ' + access_token
                    },
                    data: data
                }

                axios(config)
                    .then(function (response) {
                        self.loading = false;
                        if (response.data == true) {
                            jwt.setSubDomain(self.Subdomain);
                            self.$router.replace(getHomeRouteForLoggedInUser(jwt.getUserRole()))
                                .then(() => {
                                    self.$toast({
                                        component: ToastificationContent,
                                        position: 'top-right',
                                        props: {
                                            title: `Welcome ${jwt.getuserName() || jwt.getuserEmail()}`,
                                            icon: 'CoffeeIcon',
                                            variant: 'success',
                                            text: `You have successfully logged in. Now you can start to explore.`,
                                        },
                                    })
                                })
                        }
                        else {
                            alert("false")
                        }
                    })
                    .catch(function (error) {
                        // console.log(error);
                        self.loading = false;
                    });
            },

            fetchCountriesList: async function () {

                this.error = this.CountryList = null
                var self = this;
                this.loading = true
                var axios = require('axios');
                const access_token = await jwt.getToken();

                var headers = {
                    'Authorization': 'bearer ' + access_token
                };

                axios.get('' + this.WebAPIURL + '/api/UserProfile/GetCountriesList', { headers }).then(function (response) {
                    self.CountryList = response.data;

                    self.loading = false;
                    self.dataLoaded = true;
                })
                    .catch(function (error) {
                        self.error = error;
                        self.loading = false;
                        self.dataLoaded = true;
                        // console.log("error:: " + error)
                    });
            },
        }
    };
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
  @import '@core/scss/vue/libs/vue-wizard.scss';
  @import '@core/scss/vue/libs/vue-select.scss';
</style>

